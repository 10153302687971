import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Router from "vue-router"
import router from './router/router'
import Vuex from 'vuex'
import store from './store'
import { inject } from '@vercel/analytics'
inject()

Vue.config.productionTip = false;

Vue.use(Vuex)
Vue.use(Router)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
