import Vue from 'vue'
import Vuex from 'vuex'
import localforage from 'localforage'

Vue.use(Vuex)

const db = localforage.createInstance({
	name: "BlogsDB",
	driver: localforage.INDEXEDDB,
})

const store = new Vuex.Store({
  state: {
    blogs: null,
    loading: false,
    activePage: 'blogList'
  },
  mutations: {
    async setBlogs(state, items) {
        state.blogs = items
        await db.setItem('blogs', state.blogs)
    },
    setLoading(state, value) {
        state.loading = value
    },
    async setActivePage(state, value) {
        state.activePage = value
        await db.setItem('activePage', state.activePage)
    },
  },
  actions: {
    // actions go here
  },
  getters: {
    // getters go here
  }
})

store.init = async () => {
	if (db) {
        store.state.blogs = await db.getItem('blogs') ?? []
        store.state.loading = await db.getItem('loading') ?? false,
        store.state.activePage = await db.getItem('activePage') ?? 'BlogList'
	}	
}

export default store