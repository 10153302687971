<template>
	<div class="filter-container">
		<v-container fluid>
			<template v-if="$vuetify.breakpoint.smAndDown">
				<v-select
					v-show="items?.length > 0"
					class="category-filter"
					prepend-icon="mdi-filter"
					v-model="selected"
					:items="items"
					label="Filter by category"
					outlined
					solo
					multiple
					clearable
					small-chips
					:menu-props="{ offsetY: true  }"
				>
					<template v-slot:selection="data">
						<v-chip
							:color="getColor(data.item)"
							small
							class="ma-1"
							@click:close="remove(data.item)"
							close
						>
							<span style="color: white">{{ data.item }}</span>
						</v-chip>
					</template>
				</v-select>
			</template>
			<template v-else>
				<v-combobox
					v-show="items?.length > 0"
					class="category-filter"
					prepend-icon="mdi-filter"
					v-model="selected"
					:items="items"
					label="Filter by category"
					outlined
					solo
					multiple
					clearable
					small-chips
				>
					<template v-slot:selection="data">
						<v-chip
							:color="getColor(data.item)"
							small
							class="ma-1"
							@click:close="remove(data.item)"
							close
						>
							<span style="color: white">{{ data.item }}</span>
						</v-chip>
					</template>
				</v-combobox>
			</template>
		</v-container>
	</div>
</template>

<script>
export default {

	data() {
		return {
			selected: [],
			items: [],
			colors: ['green', 'purple', 'indigo', 'cyan', 'teal', 'orange', 'red', 'blue', 'pink'],
			categoryColors: {}
		}
	},

	watch: {
		selected() {
			this.filterBlogs()
		},
		// we have to watch on the blogs state to initialize the categories because for some reason on mounted the blogs state is not yet available (I think it's because of the async fetch)
		'$store.state.blogs': {
			handler() {
				this.initializeCategories()
			},
			immediate: true
		}
	},

	methods: {
		filterBlogs() {
			const blogs = this.$store.state.blogs
			if (!blogs) return
			const filteredBlogs = blogs.filter(blog => {
				return this.selected.some(category => blog.fields.categories.includes(category))
			})
			this.$emit('filteredBlogs', filteredBlogs)
		},
		getColor(category) {
			if (!this.categoryColors[category]) {
				const randomIndex = Math.floor(Math.random() * this.colors.length * 10) % this.colors.length
				this.categoryColors[category] = this.colors[randomIndex]
			}
			return this.categoryColors[category]
		},
		remove(category) {
			this.selected = this.selected.filter(item => item !== category)
		},
		initializeCategories() {
			this.items = this.$store?.state?.blogs?.reduce((acc, blog) => {
				blog.fields.categories.forEach(category => {
					if (!acc.includes(category)) {
						acc.push(category)
					}
				})
				return acc
			}, [])
		}
	},


}
</script>

<style scoped>

</style>el