<template>
  <v-app>
    <v-main fluid class="app-container">
      <NavBar @page-changed="(val) => $store.commit('setActivePage', val)" /> 
      <router-view class="router-view" />
      <TheFooter class="footer" @page-changed="(val) => $store.commit('setActivePage', val)" ref="footer"/>
      <SpeedInsights/>
    </v-main>
  </v-app>
</template>

<script>
import TheFooter from './components/TheFooter';
import NavBar from './components/NavBar';
import { SpeedInsights } from "@vercel/speed-insights/vue"

export default {
  name: 'App',

  components: {
    TheFooter,
    NavBar,
    SpeedInsights
  },

  data: () => ({
    //
  }),
  mounted () {
    if (process.env.VUE_APP_ENV === 'development') {
      console.log('loading feedback script');
      let script = document.createElement('script');
      script.src = 'https://vercel.live/_next-live/feedback/feedback.js';
      script.setAttribute('data-explicit-opt-in', 'true');
      script.setAttribute('data-owner-id', 'team_r1XupaiCi5gDJNBJTADJugYf');
      script.setAttribute('data-project-id', 'prj_VSxvjdvvtM2XItJC9nHQXC55pd2U');
      script.setAttribute('data-branch', 'main');
      document.head.appendChild(script);
    }
  },
};
</script>

<style>
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.router-view {
  flex: 1;
}

.footer {
  margin-top: auto;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
/* padding: 1rem; */
  /* background-color: #f5f5f5;
  text-align: center;
  color: #333;
  font-size: 0.8rem; */
}
</style>
