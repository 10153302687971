<template>
    <v-footer
      dark
      padless
    >
      <v-card
        class="flex"
        style="background: linear-gradient(to top right, #2c3e50, #000000); padding: 1rem;"
        flat
        tile
      >
        <v-card-title>
          <!-- <strong class="subheading">Get connected with me on social networks</strong> -->
          <div class="logo" @mousedown="expandLogo" @mouseup="contractLogo" @mouseleave="contractLogo" @click="handleLogoClick">
            <v-img
              alt="Personal Logo"
              contain
              src="../assets/personal_logo/29.png"
              transition="scale-transition"
              :style="{ transform: isLogoExpanded ? 'scale(1.2)' : 'scale(1)', transition: 'transform 0.3s ease' }"
              style="max-height: 50px; max-width: 100px;"
              :class="['logo-image', { 'logo-clicked': isLogoClicked }]"
            />
          </div>
  
          <v-spacer></v-spacer>
  
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4"
            dark
            icon
          >
            <v-icon size="24px" @click="redirect(icon)">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-title>
  
        <v-card-text class="py-2 white--text text-center">
            <p>Crafted by hand with <a href="https://vuejs.org/" target="_blank" class="no-decoration">Vue.js</a>, <a href="https://vuetifyjs.com/" target="_blank" class="no-decoration">Vuetify</a>, <a href="https://www.contentful.com/" target="_blank" class="no-decoration">Contentful</a> and love ❤️</p>
          <strong>{{ new Date().getFullYear() }} — COPYRIGHT &copy; BLEND DEHARI. ALL RIGHTS RESERVED.</strong>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
export default {
    data: () => ({
        icons: [
            'mdi-facebook',
            // 'mdi-twitter',
            'mdi-linkedin',
            'mdi-instagram',
        ],
        isLogoExpanded: false,
        isLogoClicked: false,
    }),

    methods: {
        redirect(icon) {
            switch (icon) {
                case 'mdi-facebook':
                    window.open('https://www.facebook.com/blend.dehari', '_blank');
                    break;
                case 'mdi-linkedin':
                    window.open('https://www.linkedin.com/in/blend-dehari-9166b1151/', '_blank');
                    break;
                case 'mdi-instagram':
                    window.open('https://www.instagram.com/blenddehari/', '_blank');
                    break;
            }
        },
        goHome() {
          if (this.$store.state.activePage !== 'blogList') {
            this.$emit('page-changed', 'blogList');
            this.$router.push({ name: 'blogList' });
            window.scrollTo(0, 1)
          }
        },
        expandLogo() {
          this.isLogoExpanded = true;
        },
        contractLogo() {
          this.isLogoExpanded = false;
        },
        handleLogoClick() {
          this.goHome();
          if (this.$vuetify.breakpoint.smAndDown) {
            this.isLogoClicked = true;
            setTimeout(() => {
              this.isLogoClicked = false;
            }, 300);
          }
        }
    },
}
</script>

<style scoped>
.logo {
  /* margin-left: 1%;
  margin-top: 1%; */
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
}

.logo:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.no-decoration {
  text-decoration: none;
  color: #2196f3;
}

@media (max-width: 600px) {
  .logo:active .logo-image {
    transform: scale(1.2);
  }
  .logo .logo-image {
    transition: transform 0.3s ease;
  }
  .logo-clicked {
    animation: pulse 0.3s ease-in-out;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>