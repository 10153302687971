<template>
	<div>
		<v-overlay :value="!blog">
			<v-progress-circular indeterminate size="64" color="#2c3e50"></v-progress-circular>
		</v-overlay>
		<!-- <div class='go-back'>
			<v-btn icon @click="$router.push({ name: 'home' })">
				<v-icon>mdi-arrow-left-thin-circle-outline</v-icon>
			</v-btn>
		</div> -->
		<!-- SEO optimisation -->
		<head>
			<meta property="og:type" content="article" />
			<meta property="article:author" content="Blend Dehari" />
			<meta property="article:section" content="Technology" />
			<meta property="article:tag" content="Vue.js" />
			<meta property="article:tag" content="Vuetify" />
			<meta property="article:tag" content="Contentful" />
			<meta property="article:tag" content="AWS" />
			<meta property="article:tag" content="Amazon Polly" />
			<meta property="article:tag" content="DALL-E" />
			<meta property="article:tag" content="OpenAI" />
			<meta property="article:tag" content="Text-To-Voice" />
			<meta property="article:tag" content="Text-To-Speech" />
			<meta property="article:tag" content="Voice Synthesis" />

			<meta property="description" content="A tech blog for curious minds" />
			<meta property="og:image" content="https://images.ctfassets.net/sgdlfo61ob4k/6Hf5Wd49dtaCCbld5jEZDM/1d6fe3c83a802a4a55680bf4fa88b7d0/29.png">
			<meta property="og:title" content="Blend Dehari's Tech Blog">
			<meta property="og:description" content="A tech blog for curious minds" />
			<meta property="og:url" content="https://www.blenddehari.com">
		</head>
		<div v-if="blog" class="blog-detail">
			<div class="blog-read-time-bar -active" data-read-time-bar="blog-page-read-time">
				<progress class="blog-read-time-bar-indicator" aria-hidden="true" max="100" :value="scrollProgress"></progress>
			</div>
			<div v-if="!$vuetify.breakpoint.smAndDown" class="blog-navigation" ref="navigationPanel">
				<h4>ON THIS PAGE</h4>
				<ul>
					<li
					v-for="(heading, index) in headings"
					:key="index"
					:class="{ selected: heading.slug === selectedHeading }"
					>
					<a
						:href="'#' + heading.slug"
						@click="scrollToHeading(heading.slug)"
					>{{ heading.text }}</a>
					</li>
				</ul>
				<div class="navigation-footer">
					<div class="social-icons">
						<v-btn
							v-for="icon in icons"
							:key="icon"
							class="mx-4"
							light
							icon
						>
							<v-icon size="24px" @click="redirect(icon)">
							{{ icon }}
							</v-icon>
						</v-btn>
					</div>
				</div>
			</div>
			<!-- version 2: render Contentful rich text using contentful-rich-text-vue-renderer -->
			<div class="blog-content" ref="contentPanel">
				<div class="blog-image-container">
					<img :src="blog.fields.image.fields.file.url" alt="Blog Image" class="blog-image" />
				</div>
				<span v-if="blog.fields.copyrightedMediaSource" class="blog-image-source" >
					<rich-text-renderer class="copyrighted-media" :document="blog.fields.copyrightedMediaSource" />
				</span>
				<div class="keywords-label">Keywords:</div>
				<div class="blog-categories">
					<div v-for="category in blog.fields.categories" :key="category" class="blog-category">{{ category }}</div>
				</div>
				<h2 class="blog-image-title">{{ blog.fields.title }}</h2>
				<div class="author-info">
					<img src="../assets/avatar_pic/48197_avatar.png" alt="Profile Avatar" class="avatar" />
					<div class="author-name">
						<span class="name">Blend</span>
						<span class="surname">Dehari</span>
						<div class="date">{{ formatDate(blog.fields.createdAt) }}</div>
						<div class="minutes-to-read">{{ blog.fields.minutesToRead }} min read</div>
					</div>
				</div>
				<div class="audio-version">
					<!-- <div class="audio-title">Listen to the audio version:</div> -->
					<audio controls>
						<source :src="blog.fields.audioPlayer.fields.file.url" type="audio/mpeg">
						Your browser does not support the audio element.
					</audio>
					<div class="audio-info">
						<span class="blog-image-source">Text-To-Voice powered by &nbsp; <a href="https://aws.amazon.com/polly/" class="no-decoration" target="_blank">Amazon Polly</a> </span>
						<!-- <a href="https://aws.amazon.com/polly/" target="_blank" class="no-decoration">Made possible with Amazon Polly</a> -->
					</div>
				</div>
				<div class="blog-html-content">
					<rich-text-renderer :document="blog.fields.content" :nodeRenderers="renderNodes()" @click="handleImageClick" />
				</div>
				<!-- Recommended post section -->
				<div v-if="blog.fields.recommendedPosts?.length">
					<h2>Recommended Blogs</h2>
					<v-sheet class="mx-auto carousel" elevation="0" max-width="800">
						<v-slide-group class="pa-4" show-arrows>
							<v-slide-item v-for="post in blog.fields.recommendedPosts" :key="post.sys.id">
								<v-card class="ma-4 recommended-card" color="grey-lighten-1"  @click="navigateToPost(post)">
									<v-img :src="post.fields.image.fields.file.url" class="recommended-card-image" cover></v-img>
									<v-card-title primary-title>
										<p class="recommended-card-title">{{ post.fields.title }}</p>
									</v-card-title>
									<v-card-text >
										<div class="recommended-card-description" v-html="convertContent(post.fields.content)"></div>
									</v-card-text>
								</v-card>
							</v-slide-item>
						</v-slide-group>
					</v-sheet>
				</div>
			</div>
			<!-- version 1: render Contentful rich text using @contentful/rich-text-html-renderer (images not supported) -->
			<!-- <div class="blog-content" ref="contentPanel">
				<h2 class="blog-title">{{ blog.fields.title }}</h2>
				<div v-html="blogContent" class="blog-html-content"></div>
			</div> -->
		</div>

	</div>
</template>


<script>
/* eslint-disable */
import { getBlogById } from "@/contentful/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { h } from "vue";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import BlogMixin from '@/mixins/BlogMixin';
import confetti from 'canvas-confetti';

const customEmbeddedAsset = (node, key) => {
	const { title, file } = node.data.target.fields;
	const assetType = file.contentType.split('/')[0];
	const assetUrl = file.url.replace('//', 'https://');

	if (assetType === 'image') {
		return h('img', {
			key,
			attrs: {
				src: assetUrl,
				alt: title,
				width: '100%',
				height: 'auto',
			},
		});
	} else if (assetType === 'video') {
		return h('video', {
			key,
			attrs: {
				src: assetUrl,
				controls: true,
				width: '100%',
				height: 'auto',
			},
		});
	} else {
		return null; // Return null for unsupported asset types
	}
};

const customHyperlink = (node, key, h) => {
	// check the hyperlink uri and if it includes youtube.com/embed then render an iframe - we do this to embed youtube videos in the blog
	if (node.data.uri.includes('youtube.com/embed')) {
		return h('div', { class: 'videoHolder' }, [
			h('iframe', {
				key,
				attrs: {
					src: node.data.uri,
					type: 'text/html',
					width: '100%',
					height: '360',
					frameborder: '0',
					allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen',
				},
			}),
		]);
	} else {
		return h('a', {
			key,
			attrs: {
				href: node.data.uri,
				target: '_blank',
				rel: 'noopener noreferrer',
			},
		}, node.content[0].value);
	}
};

export default {
	components: {
		RichTextRenderer,
	},
	mixins: [BlogMixin],
	props: {
		blogId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			blog: null,
			selectedHeading: null,
			renderers: {
				'embedded-asset-block': node => {
					const { title, file } = node.data.target.fields;
					const imageUrl = file.url.replace('//', 'https://');
					return `<img src="${imageUrl}" alt="${title}" />`;
			}
			},
			icons: [
				'mdi-facebook',
				// 'mdi-twitter',
				'mdi-linkedin',
				'mdi-instagram',
			],
			confettiTriggered: false,
			scrollProgress: 0,
		};
	},
	async created() {
		this.$store.commit("setLoading", true);
		// check if the blog is already in the store and if not, fetch it from Contentful
		this.blog = this.$store.state.blogs?.find(blog => blog.sys.id === this.blogId) ?? await getBlogById(this.blogId)
		this.$store.commit("setLoading", false);
		// set the first heading as the selected heading
		this.selectedHeading = this.headings[0].slug
	},
	computed: {
		blogContent() {
			if (!this.blog || !this.blog.fields.content) return '';
			const options = {
				renderNode: this.renderNode(this.blog.fields.content.content[5]),
			};
			console.log('computed', this.renderNode(this.blog.fields.content.content[5]))
			return documentToHtmlString(this.blog.fields.content, options);
		},
		headings() {
			if (!this.blog || !this.blog.fields.content) return [];
			const headings = [];
			const options = {
				renderNode: {
					'heading-2': (node) => {
						headings.push({
							text: node.content[0].value,
							slug: this.sanitizeSlug(node.content[0].value),
						});
					},
					'heading-1': (node) => {
						headings.push({
							text: node.content[0].value,
							slug: this.sanitizeSlug(node.content[0].value),
						});
					},
				},
			};
			documentToHtmlString(this.blog.fields.content, options);
			return headings;
		},
	},
	methods: {
		scrollToHeading(slug) {
			this.selectedHeading = slug;
			const headings = this.$refs.contentPanel.querySelectorAll('h2', 'h1'); // Assuming your headings are h2 elements
			headings.forEach((heading) => {
				if (heading.textContent.toLowerCase().replace(/ /g, '-').replace(/\?/g, '') === slug) {
					heading.scrollIntoView({ behavior: 'smooth' });
				}
			});
		},
		sanitizeSlug(text) {
			return text.toLowerCase().replace(/ /g, '-').replace(/[^a-z0-9-:]/g, '');
		},
		renderNode(node) {
			console.log('node', node)
			if (node.nodeType === 'embedded-asset-block' && node.data.target.sys.type === 'Asset') {
				console.log('image', node.data.target.fields)
				const image = node.data.target.fields;
				const imageUrl = image.file.url.replace('//', 'https://')
				console.log('imageUrl', imageUrl)
				return `<img src="${imageUrl}" alt="${image.description}" />`
			}
			return null; // Return null for other node types to use the default rendering
		},
		renderNodes() {
			return {
				[BLOCKS.EMBEDDED_ASSET]: customEmbeddedAsset,
				[INLINES.HYPERLINK]: customHyperlink,
			}
		},
		async navigateToHomePage(val) {
			await this.$store.commit('setActivePage', val)
			// this.$router.push({ name: val })
			this.$router.push({ name: 'blogList' })
		},
		redirect(icon) {
			switch (icon) {
				case 'mdi-facebook':
					window.open('https://www.facebook.com/blend.dehari', '_blank');
					break;
				case 'mdi-linkedin':
					window.open('https://www.linkedin.com/in/blend-dehari-9166b1151/', '_blank');
					break;
				case 'mdi-instagram':
					window.open('https://www.instagram.com/blenddehari/', '_blank');
					break;
			}
		},
		formatDate(date) {
			const options = { year: 'numeric', month: 'long', day: 'numeric' };
			return new Date(date).toLocaleDateString(undefined, options);
		},
		handleScroll() {
			const headings = this.$refs.contentPanel?.querySelectorAll('h2', 'h1');
			if (!headings) return;
			const scrollPosition = window.scrollY;
			const windowHeight = window.innerHeight;
    		const documentHeight = document.documentElement.scrollHeight;

			this.handleProgressBar();

			if (!this.confettiTriggered && scrollPosition + windowHeight >= documentHeight) {
				// Trigger confetti animation
				const spread = this.$vuetify.breakpoint.smAndDown ? 70 : 260;
				const duration = this.$vuetify.breakpoint.smAndDown ? 3000 : 5000;
				const count = this.$vuetify.breakpoint.smAndDown ? 300 : 650;
				 confetti({
					particleCount: count,
					spread: spread,
					origin: { y: 0.6 },
					colors: ['#FFBA57', '#2c3e50', '#3498db', '#e74c3c', '#9b59b6', '#f1c40f', '#1abc9c', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#f39c12', '#d35400', '#c0392b', '#7f8c8d', '#bdc3c7', '#ecf0f1', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#7f8c8d', '#bdc3c7', '#ecf0f1', '#95a5a6'],
					duration: duration,
				});
				this.confettiTriggered = true;
			}

			for (let i = 0; i < headings.length; i++) {
				const heading = headings[i];
				const headingOffset = heading.offsetTop;
				const nextHeadingOffset = i < headings.length - 1 ? headings[i + 1].offsetTop : Infinity;

				if (scrollPosition >= headingOffset && scrollPosition < nextHeadingOffset) {
					this.selectedHeading = this.headings[i]?.slug;
					break;
				}

				// Select the last heading if scroll position is beyond the last heading offset
				if (i === headings.length - 1 && scrollPosition >= headingOffset) {
					console.log('last heading')
					this.selectedHeading = this.headings[i]?.slug;
				}
			}
		},
		handleProgressBar(){
			const scrollTop = window.scrollY;
			const docHeight = document.documentElement.scrollHeight - window.innerHeight;
			const scrollPercent = (scrollTop / docHeight) * 100;
			this.scrollProgress = scrollPercent;
		},
		// TODO: this event is not getting triggered from the rich-text-renderer
		handleImageClick(event) {
			console.log('event', event.target.tagName)
			if (event.target.tagName === 'IMG') {
				event.target.classList.toggle('enlarge-image');
			}
		},
		navigateToPost(blog) {
			console.log('navigate to post', blog)
			this.$store.commit('setActivePage', 'blogDetail')
            this.$router.push({ name: 'blogDetail', query: { blogId: blog.sys.id } })
			// refresh the page - for some reason the page is not updating automatically when the route changes
			window.location.reload();
		},
	},
	mounted() {
		this.$store.commit('setActivePage', 'blogDetail')
		window.addEventListener('scroll', this.handleScroll);
	},

	beforeUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	metaInfo() {
		if (this.blog?.fields?.image?.fields) {
			return {
				title: "Blend Dehari's Technical Blog",
				meta: [
					{ charset: 'utf-8' },
					{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
					{ property: 'og:image', content: this.blog.fields.image.fields.file.url },
					{ hid: 'og:title', property: 'og:title', content: this.blog.fields.title },
					{ hid: 'og:description', property: 'og:description', content: this.blog.fields.content },
					{ hid: 'og:image', property: 'og:image', content: this.blog.fields.image.fields.file.url },
					{ hid: 'og:url', property: 'og:url', content: 'https://blenddehari.com' },
					{ hid: 'og:type', property: 'og:type', content: 'article' },
					{ hid: 'title', name: 'title', content: "Blend Dehari's Tech Blog" },
					{ hid: 'description', name: 'description', content: 'A technical blog for curious minds by Blend Dehari' },

					{ hid: 'article:published_time', property: 'article:published_time', content: this.blog.fields.createdAt },
					{ hid: 'article:modified_time', property: 'article:modified_time', content: this.blog.fields.updatedAt },
					{ hid: 'article:author', property: 'article:author', content: 'Blend Dehari' },
					{ hid: 'article:section', property: 'article:section', content: 'Technology' },
					{ hid: 'article:tag', property: 'article:tag', content: this.blog.fields.categories },
					{ hid: 'article:tag', property: 'article:tag', content: 'Vue.js' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Vuetify' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Contentful' },
					{ hid: 'article:tag', property: 'article:tag', content: 'AWS' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Amazon Polly' },
					{ hid: 'article:tag', property: 'article:tag', content: 'DALL-E' },
					{ hid: 'article:tag', property: 'article:tag', content: 'OpenAI' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Text-To-Voice' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Text-To-Speech' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Voice Synthesis' },
				]
			}
		} 
		else {
			return {
				title: "Blend Dehari's Technical Blog",
				meta: [
					{ charset: 'utf-8' },
					{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
					{ hid: 'og:title', property: 'og:title', content: "Blend Dehari's Technical Blog" },
					{ hid: 'og:description', property: 'og:description', content: 'A technical blog for curious minds by Blend Dehari' },
					// personal logo as the default image
					{ hid: 'og:image', property: 'og:image', content: 'https://images.ctfassets.net/sgdlfo61ob4k/6Hf5Wd49dtaCCbld5jEZDM/1d6fe3c83a802a4a55680bf4fa88b7d0/29.png' },
					{ hid: 'og:url', property: 'og:url', content: 'https://blenddehari.com' },
					{ hid: 'title', name: 'title', content: "Blend Dehari's Tech Blog" },
					{ hid: 'description', name: 'description', content: 'A technical blog for curious minds by Blend Dehari' },
					// tags
					{ hid: 'og:type', property: 'og:type', content: 'article' },
					{ hid: 'article:author', property: 'article:author', content: 'Blend Dehari' },
					{ hid: 'article:section', property: 'article:section', content: 'Technology' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Vue.js' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Vuetify' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Contentful' },
					{ hid: 'article:tag', property: 'article:tag', content: 'AWS' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Amazon Polly' },
					{ hid: 'article:tag', property: 'article:tag', content: 'DALL-E' },
					{ hid: 'article:tag', property: 'article:tag', content: 'OpenAI' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Text-To-Voice' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Text-To-Speech' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Voice Synthesis' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Cloud Computing' },
					{ hid: 'article:tag', property: 'article:tag', content: 'Tech Blog' },
				]
			}
		}
	}
};
</script>

<style scoped>
.blog-detail {
	display: flex;
	flex-direction: row;
	margin: 0 2rem;
}

.blog-title {
	font-size: 2.5rem;
	margin: 2rem 0;
	text-align: center;
}

.blog-content {
	width: 100%;
	max-width: 800px;
	padding: 2rem;
}

/* blog0content for very big screens */
@media (min-width: 1200px) {
	.blog-content {
		margin: 0 auto;
		margin-left: 10%;
	}
}

@media (min-width: 768px) {
	.blog-content {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
	}
}

.blog-html-content {
	text-align: left;
	line-height: 1.6;
}

.blog-html-content p {
	margin: 1.2rem 0;
	/* font will be Georgia */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.blog-html-content img {
	max-width: 100%;
	/* max-width: 800px; */
	height: auto;
	display: block;
	margin: 2rem auto;

}

.blog-html-content h2 {
	font-size: 2rem;
	margin-top: 2rem;
}

.blog-html-content a {
	color: #3498db;
	text-decoration: none;
}

.blog-html-content a:hover {
	text-decoration: underline;
}

/* Add responsive styles for mobile screens */
@media screen and (max-width: 768px) {
	.blog-content {
		padding: 1rem;
	}
	.blog-title {
		font-size: 2rem;
		margin: 1rem 0;
	}
}

.blog-content blockquote {
	background: #f9f9f9;
	border-left: 10px solid #ccc;
	margin: 1.5em 10px;
	padding: 0.5em 10px;
	quotes: "\201C""\201D""\2018""\2019";
}

.blog-content blockquote:before {
	content: open-quote;
	font-size: 3em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

.go-back {
	position: fixed;
	top: 1rem;
	left: 1rem;
	z-index: 100;
}

@media screen and (max-width: 768px) {
	.go-back {
		top: 0.5rem;
		left: 0.5rem;
	}
}

.blog-navigation {
	position: sticky;
	top: 1.5rem;
	margin-top: 4rem;
	margin-left: 2rem;
	margin-right: 2rem;
	flex: 0 0 20%;
	height: calc(100vh - 4rem); /* set the height to the viewport height minus the header and footer height */
	overflow-y: auto;
}

.blog-navigation h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: bold;
}

.blog-navigation ul {
  list-style: none;
  padding: 0;
}

.blog-navigation li {
  border-left: 2px solid #eee;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.blog-navigation li a {
  text-decoration: none;
  color: black;
  font-weight: normal;
}

.blog-navigation li.selected {
	font-weight: bold;
	border-left: 2px solid #FFBA57;
}

.blog-navigation li.selected a {
	font-weight: bold;
}

.audio-version {
	margin-top: 2rem;
	margin-bottom: 2rem;
	text-align: center;
}

.audio-info {
	font-size: 0.8rem;
	color: #999;
}

.audio-title {
	font-weight: bold;
}

.blog-image-container {
	position: relative;
	height: auto;
}

@media screen and (max-width: 768px) {
	.blog-image-container {
		margin-top: 2rem;
	}
}

.blog-image {
	width: 100%;
	height: auto;
}

.blog-image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 98.5%; /* set default height to 99% */
	background-color: rgba(0, 0, 0, 0);
	/* color: #fff; */
	color:#15171a;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	padding: 1rem;
}

@media screen and (max-width: 768px) {
	.blog-image-overlay {
		height: 97%; /* set height to 97% on mobile screens */
	}
}

@media screen and (max-width: 768px) {
	.blog-html-content h2 {
		font-size: 1.5rem;

	}
}

.blog-image-title {
	font-size: 2rem;
	text-align: center;
	padding: 1rem;
	margin-top: 1rem;
}
@media screen and (max-width: 768px) {
	.blog-image-title {
		font-size: 1rem;
		bottom: 1rem;
		padding: 0.5rem;
	}
}

.blog-image-source {
	font-size: 0.8rem;
	color: #999;
	text-align: center;
	margin-top: 0.5rem;
	display: inline-flex;
}

.blog-image-source a {
	text-decoration: none;
	color: #3498db;
}

.blog-categories {
	display: flex;
	flex-wrap: wrap;
	/* position: absolute; */
	/* top: 0;
	left: 0; */
	/* padding: 0.5rem; */
	color: #999;
	font-weight: bold;
	border-radius: 0.5rem;
}

.blog-category {
	padding: 5px;
	margin: 5px;
	border-radius: 0.5rem;
	color: #999(0, 0, 0, 0.8);
	border: 1px solid rgba(153, 153, 153, 0.8);
	font-size: 0.8rem;
}

.keywords-label {
	font-weight: bold;
	margin: 5px;
	color: #999;
}

@media screen and (max-width: 768px) {
	.keywords-label {
		font-size: 0.8rem;
	}
}

@media screen and (max-width: 768px) {
	.blog-categories {
		flex-wrap: wrap;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		white-space: nowrap;
		padding: 0px;
		margin: 1px;
	}

	.blog-category {
		font-size: xx-small;
		padding: 2px 5px;
		color: #999(255, 255, 255, 0.8);
		border: 1px solid #999(255, 255, 255, 0.8);
	}
}

.author-info {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 2rem 0;
}

.author-info .avatar {
	width: 66px;
	height: 66px;
	border-radius: 50%;
	margin-right: 1rem;
}

/* avatar on hover make image bigger */
/* .author-info .avatar:hover {
	width: 60px;
	height: 60px;
} */

.author-info .author-name {
	display: inline-block;
	/* font-size: 1.2rem; */
	/* font-weight: bold; */
}

.author-info .author-name .name {
	display: inline;
	font-weight: bold;
	color: #15171a;
}

.author-info .author-name .surname {
	display: inline;
	font-weight: bold;
	color: #15171a;
	margin-left: 0.5rem;
}

.author-info .minutes-to-read {
	display: block;
	font-size: 0.8rem;
	margin-top: 0.5rem;
	color: #999;
}

.navigation-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 2rem;
}

.social-icons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.date {
	font-size: 0.8rem;
	color: #999;
}

.no-decoration {
	text-decoration: none;
	color: #3498db;
}

.blog-html-content img {
	max-width: 100%;
	height: auto;
	display: block;
	margin: 2rem auto;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}

/* enlarge the image on hover */
.blog-html-content img:hover {
	transform: scale(1.4);
	transition: transform 0.2s ease-in-out;
}


.blog-html-content img.enlarge-image {
	transform: scale(1.2);
	transition: transform 0.2s ease-in-out;
}

@media (min-width: 768px) {
	.blog-html-content img:hover {
		transform: scale(1.8);
		transition: transform 0.2s ease-in-out;
	}
}

/* recommended post section */
.recommended-post:hover {
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
	scale: 1.05;
}

.recommended-card {
	height: 400px;
	width: 300px;
}

@media screen and (max-width: 768px) {
	.recommended-card {
		height: 200px;
		width: 120px;
	}
}

.recommended-card-image {
	height: 200px;
}

.recommended-card:hover {
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
	transform: scale(1.05);
}

@media screen and (max-width: 768px) {
	.recommended-card-image {
		height: 120px;
	}
}

.recommended-card-title {
	font-size: 1.2rem;
	line-height: 1.2;
}

@media screen and (max-width: 768px) {
	.recommended-card-title {
		font-size: 0.5rem;
		line-height: 1.2;
	}
	
}

.carousel {
	/* negative horizontal margin so it occupies a bit more space */
	margin-left: -28px !important;
	margin-right: -28px !important;
}

@media screen and (max-width: 768px) {
	.carousel {
		margin-left: -48px !important;
		margin-right: -48px !important;
	}
	
}

.recommended-card-description {
	font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
	.recommended-card-description {
		display: none;
		/* font-size: 0.5rem; */
	}
}


/* Equivalent of window.scrollTo(0, 1) to hide/ignore the hidden URL bar in mobile phones */
@media screen and (max-width: 768px) {
	html {
		scroll-padding-top: 1px;
		scroll-behavior: smooth;
	}
	body {
		scroll-margin-top: 1px;
	}
}

.blog-read-time-bar.-active {
  display: flex;
}
@media (min-width: 600px) {
  .blog-read-time-bar {
    height: .5rem;
  }
}
.blog-read-time-bar {
  display: none;
  height: 10px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 99999;
}

.blog-read-time-bar-indicator {
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
}

.blog-read-time-bar-indicator::-webkit-progress-bar {
  background-color: transparent;
}

.blog-read-time-bar-indicator::-webkit-progress-value {
  background-image: linear-gradient(to right, #2c3e50, #3498db);
  transition: width 0.2s;
}

.blog-read-time-bar-indicator::-moz-progress-bar {
  background-image: linear-gradient(to right, #2c3e50, #3498db);
  transition: width 0.2s;
}

/* if you want to rotate images on scroll effect */
/* .blog-image {
	animation: rotate-image linear;
	animation-timeline: scroll();
}

@keyframes rotate-image {
	0% {
		transform: rotateY(0deg);
	}
	20% {
		transform: rotateY(180deg);
	}
} */

/* how to best enlarge the image on mobile devices */
/* @media screen and (max-width: 768px) {
	.blog-html-content img {
		max-width: 100%;
		height: auto;
		display: block;
		margin: 2rem auto;
		cursor: pointer;
		transition: transform 0.3s ease-in-out;
	}
} */

</style>
