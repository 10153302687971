import VueRouter from 'vue-router';
import BlogDetail from '../components/BlogDetail.vue';
import TheHome from '../components/TheHome.vue';
import AboutMe from '../components/AboutMe.vue';

const router = new VueRouter({
  mode: 'history', // add this line to use history mode
  routes: [
    { path: '/', redirect: '/blogList' },
    { path: '/blogList', name: 'blogList', component: TheHome },
    { path: '/blogDetail', name: 'blogDetail', component: BlogDetail, props: route => ({ blogId: route.query.blogId })},
    { path: '/about', name: 'about', component: AboutMe },
    { path: '*', redirect: '/blogList' }
    ]
})

export default router