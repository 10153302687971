<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
	data: () => ({
	
	}),
	computed: {
		
	},
	methods: {
		convertContent(content) {
			const htmlContent = documentToHtmlString(content)
			// return the first header and the first paragraph of the blog (merged)
			const text = htmlContent.split('<p>')[1].split('</p>')[0]
			let maxLength = 125 // maximum length of the text
			// if (this.blog.fields.isPrimary) {
			// 	maxLength = 300 // set a larger maxLength for isPrimary blogs
			// } else if (this.blog.fields.isSecondary) {
			// 	maxLength = 170 // set a large but smaller maxLength for isSecondary blogs
			// }
			if (text.length > maxLength) {
				return text.slice(0, maxLength) + '...' // add three dots to the end
			}
			return text
		}
	}
}
</script>
