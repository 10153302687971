<template>
    <div id="app">
        <v-overlay :value="$store.state.loading">
            <v-progress-circular indeterminate size="64" color="#2c3e50"></v-progress-circular>
        </v-overlay>
        <v-card outlined color="transparent" overflow-y="auto">

            <div :style="!$vuetify.breakpoint.smAndDown ? 'margin-top: 4rem;' : 'margin-top: 3rem'" v-show="$store.state.activePage==='blogList' || $store.state.activePage ==='blogDetail'">
				<div style="display: flex; flex-direction: row; flex-grow:1;">
					<BlogList/>
				</div>
            </div>

        
            <div :style="!$vuetify.breakpoint.smAndDown ? 'margin-top: 4rem;' : 'margin-top: 3rem'" v-show="$store.state.activePage==='about'">
                <AboutMe/>
            </div>
            
        </v-card>
    </div>
</template>

<script>
import BlogList from '@/components/BlogList.vue';
import AboutMe from '@/components/AboutMe.vue';
// import dayjs from 'dayjs'

export default {
    components: {
        BlogList,
        AboutMe,
    },

  data () {
    return {
        menu: false,
    }
  },
mounted () {
  },
  
  computed: {

  },
  methods: {

  }
}
</script>

<style scoped>
#app {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

}

.logo {
    margin-left: 1%;
     margin-top: 1%; 
     display: flex; 
     align-items: center; 
     justify-content: center;
}

.logo:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
}

.custom-tab {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: color 0.3s ease;
}

/* on hover custom tab only dim the text and do not show any backgroudn effects */
.custom-tab:hover {
    color: #ffffff;
    background-color: transparent;    
}

.nav-drawer {
    background-color: #CD853F;
}

.custom-list-item {
    color: #ffffff;
    font-size: 0.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: color 0.3s ease;
}

.active {
    color: #ffffff;
    background-color: #000000;
}

.filter-container {
	margin-right: 1rem;
	/* align-items: center; */
}
</style>