import contentfulConfig from './config';
import { createClient } from 'contentful';

// Create a Contentful client
const client = createClient({
  space: contentfulConfig.spaceId,
  accessToken: contentfulConfig.accessToken,
});

// Function to fetch content
export async function fetchBlogs(contentType) {
  try {
    const response = await client.getEntries({
      content_type: contentType,
    });
    return rankBlogs(response.items)
  } catch (error) {
    console.error('Error fetching content from Contentful:', error);
    throw error;
  }
}

function rankBlogs(blogs) {
	const primaryBlogs = []
	const secondaryBlogs = []
	const otherBlogs = []

	// Categorize blogs based on isPrimary and isSecondary fields
	blogs.forEach((blog) => {
		if (blog.fields.isPrimary) {
			primaryBlogs.push(blog)
		} else if (blog.fields.isSecondary) {
			secondaryBlogs.push(blog)
		} else {
			otherBlogs.push(blog)
		}
	});

	// Sort otherBlogs by createdAt date
	otherBlogs.sort((a, b) => {
		const dateA = new Date(a.fields.createdAt)
		const dateB = new Date(b.fields.createdAt)
		return dateB - dateA
	})

	// return blogs in order of primary, secondary, other
	const rankedBlogs = [...primaryBlogs, ...secondaryBlogs, ...otherBlogs]

	return rankedBlogs
}

export async function getBlogById(blogId) {
    try {
      const response = await client.getEntry(blogId);
      return response
    } catch (error) {
      console.error('Error fetching blog by ID:', error);
      throw error;
    }
}
