<template>
  <v-app-bar
    dark
    shrink-on-scroll
    prominent
    fade-img-on-scroll
    gradient="to top right, #2c3e50, #000000"
    height="80"
  >
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, #2c3e50, #000000"
      ></v-img>
    </template>

    <div class="logo" @mousedown="expandLogo" @mouseup="contractLogo" @mouseleave="contractLogo" @click="handleLogoClick">
      <v-img
        alt="Personal Logo"
        contain
        src="../assets/personal_logo/29.png"
        transition="scale-transition"
        :width="$vuetify.breakpoint.smAndDown ? 100 : 100"
        :style="{ transform: isLogoExpanded ? 'scale(1.2)' : 'scale(1)', transition: 'transform 0.3s ease' }"
        :class="['logo-image', { 'logo-clicked': isLogoClicked }]"
      />
    </div>

    <v-spacer></v-spacer>

    <div class="title">
      <v-toolbar-title >
        <span class="title-font">A blog for curious minds</span>
        <span class="subtitle-font">by Blend Dehari</span>
      </v-toolbar-title>
    </div>

    <v-btn class="burger-button" icon v-if="$vuetify.breakpoint.smAndDown" @click="menu = !menu">
      <v-icon>mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="menu"
      absolute
      temporary
      right
      color="#22272c"
      class="nav-drawer"
      app
      height="auto"
    >
      <v-list>
        <v-list-item-group active-class="#958a56">
          <v-list-item
            v-for="page in pages"
            :key="page.id"
            @click="changeTab(page.id)"
            :class="{ active: $store.state.activePage === page.id, 'custom-list-item': true }"
          >
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <template v-if="!$vuetify.breakpoint.smAndDown">
      <v-tabs right style="margin-right: 1%;">
        <v-tab
          v-for="page in pages"
          :key="page.title"
          class="custom-tab"
          @click="changeTab(page.id)"
        >
          <!-- <v-icon>{{ page.icon }}</v-icon> -->
          <span>{{ page.title }}</span>
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      menu: false,
      isLogoExpanded: false,
      isLogoClicked: false
    };
  },
  computed: {
    pages() {
      return [
        { id: 'blogList', title: 'Blog', icon: 'mdi-post', route: { name: 'blogList' } },
        { id: 'about', title: 'About', icon: 'mdi-account', route: { name: 'about' } },
      ];
    },
  },
  methods: {
    goHome() {
      if (this.$store.state.activePage !== 'blogList') {
        this.$emit('page-changed', 'blogList');
        this.$router.push({ name: 'blogList' });
      }
    },
    changeTab(pageId) {
      if (this.$store.state.activePage !== pageId) {
        this.$emit('page-changed', pageId);
        
        if (pageId === 'about') {
          console.log('about page')
          this.$router.push({ name: 'about' });
        } else {
          console.log('blogList page')
          this.$router.push({ name: 'blogList' });
        }
      }
    },
    expandLogo() {
        this.isLogoExpanded = true;
    },
    contractLogo() {
      this.isLogoExpanded = false;
    },
    handleLogoClick() {
      this.goHome();
      if (this.$vuetify.breakpoint.smAndDown) {
        this.isLogoClicked = true;
        setTimeout(() => {
          this.isLogoClicked = false;
        }, 300);
      }
    }
  },
};
</script>

<style scoped>
.logo {
  margin-left: 1%;
  margin-top: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (min-width: 601px) {
  .logo:hover {
    transform: scale(1.2);
    transition: transform 0.3s ease;
  }
}

@media (max-width: 600px) {
  .logo:active .logo-image {
    transform: scale(1.2);
  }
  .logo .logo-image {
    transition: transform 0.3s ease;
  }
  .logo-clicked {
    animation: pulse 0.3s ease-in-out;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.custom-tab {
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: color 0.3s ease;
}

/* on hover custom tab only dim the text and do not show any background effects */
.custom-tab:hover {
  color: #ffffff;
  background-color: transparent;
}

.nav-drawer {
  background-color: #cd853f;
}

.custom-list-item {
  color: #ffffff;
  font-size: 0.5rem;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: color 0.3s ease;
  width: 120%;
  margin-left: -5px;
}

.active {
    color: #ffffff;
}

.burger-button {
  /* center vertically */
  margin-top: 0.5rem;  
}

.title {
  width:  -webkit-fill-available;
  margin-left: 1%;
  margin-top: 0.5rem;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* cursor: pointer; */
  transition: transform 0.3s ease;

}
.title-font {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* margin-bottom: 0.5rem; */
  display: flex;
  flex-direction: column;
  /* font-family: 'Courier New', Courier, monospace; */
  /* font-family: monospace; */
}

.subtitle-font {
  font-size: 0.8rem;
  font-weight: bold;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* margin-top: 0.5rem; */
  display: flex;
  flex-direction: column;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media (max-width: 600px) {
  .title {
    margin-left: 1rem;
    margin-top: 1rem;
    justify-content: center;
  }

  .title-font {
    font-size: 1rem;
    margin-right: 1rem;
  }

  .subtitle-font {
    /* display: none; */
  }
}
</style>